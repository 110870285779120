a.link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden
}

body,
html {
	width: 100%;
	min-width: 320px;
	height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	background: #292929;
	color: #fff;
	font-family: Poppins, sans-serif;
	-webkit-tap-highlight-color: transparent;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%
}

*,
::after,
::before {
	-webkit-box-sizing: inherit;
	box-sizing: inherit
}

::-moz-selection {
	background: #fff;
	color: #040FD9
}

::selection {
	background: #fff;
	color: #040FD9
}

img::-moz-selection {
	background: rgba(0, 0, 0, 0)
}

img::selection {
	background: rgba(0, 0, 0, 0)
}

img {
	display: block;
	max-width: 100%;
	height: auto;
	border: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

mark {
	background: rgba(0, 0, 0, 0);
	color: #fff
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block
}

blockquote,
dl,
figure,
hr,
menu,
ol,
p,
pre,
summary,
table,
ul {
	margin: 0;
	padding: 0
}

ol,
ul {
	list-style: none
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	font-weight: 400
}

fieldset,
legend {
	margin: 0;
	padding: 0;
	border: 0
}

label {
	-ms-touch-action: manipulation;
	touch-action: manipulation
}

button,
input,
progress,
select,
textarea {
	margin: 0;
	border-radius: 0;
	overflow: hidden;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

button,
select {
	text-transform: none;
	cursor: pointer
}

select::-ms-expand {
	display: none
}

select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #fff
}

button {
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: 0 0
}

button[disabled],
input[disabled] {
	cursor: default
}

input[type=checkbox],
input[type=image],
input[type=radio] {
	display: inline-block;
	width: auto;
	cursor: pointer
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0
}

textarea {
	resize: none
}

table {
	border-spacing: 0;
	border-collapse: collapse;
	empty-cells: show
}

iframe,
video {
	max-width: 100%
}

svg,
svg use {
	pointer-events: none
}

abbr {
	cursor: help
}

* {
	outline: 0
}

* {
	scrollbar-width: thin;
	scrollbar-color: rgba(255, 255, 255, .2) rgba(255, 255, 255, .2)
}

::-webkit-scrollbar {
	width: 12px
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(255, 255, 255, .2)
}

::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, .2);
	border-radius: 20px
}

.screader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	border: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0)
}

.screader-focus:active,
.screader-focus:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto
}

a {
	color: #fff;
	text-decoration: underline
}

a:focus,
a:hover {
	text-decoration: none
}

b,
strong {
	font-weight: 700
}

em,
i {
	font-weight: 400;
	font-style: italic
}

sup {
	font-size: .5em
}

.v-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: center;
	align-content: center;
	max-width: 100%;
	min-height: 100%
}

.v-center-container {
	-ms-flex-item-align: center;
	align-self: center
}

html {
	height: 100%;
	min-height: 600px;
	-ms-scroll-chaining: none;
	overscroll-behavior: none
}

body {
	height: 100%;
	min-height: 650px;
	background: #daddec;
	font-family: Poppins, sans-serif;
	-ms-scroll-chaining: none;
	overscroll-behavior: none
}

#app {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	padding: min(20px, 3%)
}

#player {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 460px;
	max-width: 100%;
	height: 100%;
	max-height: 990px;
	border-radius: 50px;
	-webkit-box-shadow: 0 8px 35px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 8px 35px 0 rgba(0, 0, 0, .2);
	background: linear-gradient(135deg, #040FD9 0, #030BA6);
	color: #fff;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.player-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 40px 40px 20px
}

.player-header-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 2rem;
	font-weight: 500
}

.player-header-title svg {
	width: 70px;
	aspect-ratio: 3 / 1.5;
}

.player-header-avatar {
	position: relative;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: 10px;
	border-radius: 1000px;
	box-shadow: none;
	cursor: pointer;
}

.player-header-avatar:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 90%;
	border-radius: 1000px;
	box-shadow: none;
	background: #fff;
	-webkit-transform: translate3d(-50%, -50%, 0);
	-ms-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
	-webkit-transition: width .4s .2s cubic-bezier(.25, 1.1, .3, 1.1), height .4s .2s cubic-bezier(.25, 1.1, .3, 1.1);
	transition: width .4s .2s cubic-bezier(.25, 1.1, .3, 1.1), height .4s .2s cubic-bezier(.25, 1.1, .3, 1.1), box-shadow .3s;
	z-index: 1;
	aspect-ratio: 1/1
}

.player-header-avatar:after {
	content: "";
	position: relative;
	display: block;
	width: 60px;
	border: 3px solid #fff;
	border-radius: 100px;
	-webkit-box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 8px 25px 0 rgba(0, 0, 0, .2);
	background: url(../images/icon-close.svg) no-repeat center rgba(0, 0, 0, .8);
	background-size: 36px auto;
	z-index: 2;
	aspect-ratio: 1/1
}

.player-header-avatar img {
	position: absolute;
	inset: 50% 0 0 50%;
	display: inline-block;
	vertical-align: top;
	width: 54px;
	border-radius: 50%;
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	-webkit-transition: opacity .2s, -webkit-transform .3s, filter .3s;
	transition: opacity .2s, -webkit-transform .3s, filter .3s;
	transition: transform .3s, opacity .2s, filter .3s;
	transition: transform .3s, opacity .2s, -webkit-transform .3s, filter .3s;
	z-index: 3
}

body:not(.player-extended) .player-header-avatar:hover img {
	filter: brightness(1.5);
}

.player-header-avatar span {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 20px;
	height: 20px;
	border: 3px solid #fff;
	border-radius: 50%;
	background-color: #1BF2A3;
	transform: scale(1);
	transition: transform .2s;
	z-index: 3;
}

.player-header-description {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 110px 40px 40px;
	color: #333;
	text-align: center;
	-webkit-transition: padding .2s;
	transition: padding .2s;
	z-index: 1;
	pointer-events: none
}

.player-header-description-container {
	height: 100%;
	opacity: 0;
	-webkit-transition: all .3s;
	transition: all .3s;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	overflow: hidden;
	overflow-y: auto;
	visibility: hidden
}

.player-header-description-container h2 {
	margin-bottom: 20px;
	font-weight: 500
}

.player-header-description-container p {
	margin-bottom: 15px
}

.player-header-description-container :last-child {
	margin-bottom: 0
}

.player-header-social {
	display: flex;
	justify-content: center;
}

.player-header-social-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 20%;
	padding: 10px;
}

.player-header-social-item a {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0 0 0 0 transparent;
	transition: box-shadow .2s;
}

.player-header-social-item a:hover {
	box-shadow: 0 0 0 5px #1bf2a3;
}

.player-header-social-item svg {
	width: 40px;
	height: 40px;
}



body.avatar-open .player-header-avatar:before {
	width: clamp(1400px, 3000px, 400vw);
	-webkit-transition-delay: 0s;
	transition-delay: 0s
}

body.avatar-open .player-header-avatar img {
	opacity: 0;
	-webkit-transform: translate(-50%, -50%) scale(0);
	-ms-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0)
}

body.avatar-open .player-header-avatar span {
	transform: scale(0);
}

body.avatar-open .player-header-description {
	pointer-events: all
}

body.avatar-open .player-header-description-container {
	opacity: 1;
	-webkit-transition-delay: .2s;
	transition-delay: .2s;
	visibility: visible
}

body.avatar-open .player-controls-handle:before,
body.avatar-open .player-controls-handle:after {
	background: #a1a1a1
}

.player-content {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	height: calc(100% - 120px);
	padding: 0 min(20px, 3%) 10px;
	-webkit-transition: padding .1s 0s;
	transition: padding .1s 0s
}

.player-playlist {
	height: 100%;
	padding: 0 calc(min(20px, 3%) - 10px);
	overflow: hidden;
	overflow-y: auto;
	-ms-scroll-snap-type: y mandatory;
	scroll-snap-type: y mandatory
}

.player-playlist-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 5px;
	padding: 10px;
	border-radius: 22px;
	-webkit-transition: margin .2s, background .2s, outline .2s;
	transition: margin .2s, background .2s, outline .2s;
	outline: 0 solid transparent;
	cursor: pointer;
	scroll-snap-align: start;
}

.desktop .player-playlist-item:hover {
	outline: 4px solid rgba(255, 255, 255, .1);
	outline-offset: -4px;
}

.player-playlist-item.active,
.player-playlist-item.active:hover {
	background: linear-gradient(160deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .3));
}


.player-playlist-item.active figure .equalizer {
	opacity: .8;
}

.player-playlist-item.active figure:before {
	opacity: 1;
}

.player-playlist-item.loading.active figure .equalizer {
	opacity: 0;
}

.player-playlist-item.loading figure:after {
	opacity: .8;
}

.player-playlist-item h3 {
	flex: 1 1 auto;
	margin-right: 10px;
	margin-left: 20px;
	font-weight: 500;
	line-height: 1.125em;
}

.player-playlist-item h3 span {
	display: block;
	font-size: .7em;
	font-weight: 300;
	opacity: .7;
}

.player-playlist-item h4 {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: auto;
	opacity: .7
}

.player-playlist-item h4:empty {
	opacity: 1;
}

.player-playlist-item h4:empty:before {
	content: 'live';
	padding: 2px 5px;
	border-radius: 5px;
	background: #1BF2A3;
	color: #000;
	line-height: 1em;
}

.player-playlist-item figure {
	position: relative;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	transition: opacity .2s;
}

.player-playlist-item figure:before {
	content: '';
	position: absolute;
	inset: 0;
	width: 100%;
	border-radius: 15px;
	background: rgba(0, 0, 0, .4);
	opacity: 0;
	transition: opacity .2s;
	aspect-ratio: 1/1;
}

.player-playlist-item figure:after {
	content: '';
	position: absolute;
	inset: 0;
	width: 100%;
	border-radius: 15px;
	background: url('../images/icon-loading.svg') center no-repeat;
	background-size: 71% auto;
	opacity: 0;
	transition: opacity .2s;
	aspect-ratio: 1/1;
}

.player-playlist-item figure .img {
	width: 70px;
	border-radius: 15px;
	-webkit-box-shadow: 0 8px 15px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 8px 15px 0 rgba(0, 0, 0, .2);
	background-color: rgba(0, 0, 0, .3);
	background-image: url('../images/icon-loading.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 71% auto;
	aspect-ratio: 1/1;
}

.player-playlist-item figure .equalizer {
	position: absolute;
	inset: 20%;
	width: 60%;
	aspect-ratio: 1/1;
	transition: opacity .2s;
	opacity: 0;
}

.player-controls {
	position: absolute;
	bottom: -90px;
	left: -1px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	width: calc(100% + 2px);
	height: 110px;
	font-size: .9em;
	transform: translate3d(0, 0, 0);
	transition: height .8s cubic-bezier(.25, 1.1, .3, 1.1), box-shadow .3s, bottom .3s;
	z-index: 10;
	isolation: isolate;
}

.player-controls-handle {
	position: absolute;
	top: 0;
	left: 50%;
	width: 100%;
	height: 20px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: background .2s, top .2s;
	transition: background .2s, top .2s;
	cursor: pointer;
	z-index: 1;
}

.player-controls-handle:before,
.player-controls-handle:after {
	content: '';
	position: absolute;
	top: 7px;
	left: 50%;
	width: 26px;
	height: 6px;
	margin-left: -6px;
	border-radius: 10px;
	background: #fff;
	transform-origin: 10% -8px;
	transition: transform .2s, width .2s;
}

.player-controls-handle:after {
	right: 50%;
	left: auto;
	margin-right: -6px;
	margin-left: auto;
	transform-origin: 90% -5px;
}

.player-controls-wrapper {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	padding: 20px min(40px, 6%);
	border-radius: 50px;
	-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, .2);
	box-shadow: 0 0 0 0 rgba(0, 0, 0, .2);
	background: rgba(255, 255, 255, .6);
	color: #333;
	-webkit-transition: padding .6s, -webkit-box-shadow .3s;
	transition: padding .6s, -webkit-box-shadow .3s;
	transition: padding .6s, box-shadow .3s;
	transition: padding .6s, box-shadow .3s, -webkit-box-shadow .3s;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
}

.player-controls-details {
	position: absolute;
	left: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 70px);
	padding: 0 20px 0 calc(min(40px, 6%) + 20px);
	opacity: 1;
	-webkit-transition: opacity .1s .9s;
	transition: opacity .1s .9s;
	z-index: -1;
}

.player-controls-details-title {
	flex: 1 1 auto;
}

.player-controls-details-button {
	flex: 0 0 50px;
	width: 50px;
	height: 56px;
}

.player-controls-buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: end;
	align-self: flex-end;
	width: 50px;
	height: 70px;
	margin-left: auto;
	text-align: center;
	-webkit-transition: width .2s .3s, opacity .1s;
	transition: width .2s .3s, opacity .1s
}

.player-controls-btn {
	position: relative;
	width: 0;
	height: 50px;
	padding: 0;
	font-size: 0;
	line-height: 1em;
	opacity: .5;
	-webkit-transition: width .2s .2s, margin .2s .2s;
	transition: width .2s .2s, margin .2s .2s
}

.player-controls-btn svg {
	width: 100%;
	fill: #030BA6;
	aspect-ratio: 1/1
}

.player-controls-prev svg {
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg)
}

.player-controls-play {
	width: 50px;
	margin-left: 0;
	opacity: 1
}

.player-controls-seek {
	width: 0;
	padding: 0;
	-webkit-transition: width .2s .2s, padding .2s .2s, opacity .3s;
	transition: width .2s .2s, padding .2s .2s, opacity .3s
}

.player-controls-seek-bar {
	width: 100%;
	height: 5px;
	border-radius: 10px;
	background: rgba(25, 24, 147, .3)
}

.player-controls-seek-bar-progress {
	position: relative;
	width: 0;
	height: 100%;
	border-radius: 10px;
	background: -webkit-gradient(linear, left top, right top, from(#040FD9), to(#030BA6));
	background: linear-gradient(to right, #040FD9, #030BA6);
	-webkit-transition: width .2s cubic-bezier(.25, 1.1, .3, 1.1);
	transition: width .2s cubic-bezier(.25, 1.1, .3, 1.1);
	cursor: pointer
}

.player-controls-seek-bar-progress:before {
	content: "";
	position: absolute;
	inset: 50% 0 0 100%;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	-webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
	background: linear-gradient(45deg, #040FD9, #030BA6);
	-webkit-transform: translate(-50%, -50%) scale(0);
	-ms-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	-webkit-transition: width .2s, height .2s, -webkit-box-shadow .2s, -webkit-transform .2s .3s;
	transition: width .2s, height .2s, -webkit-box-shadow .2s, -webkit-transform .2s .3s;
	transition: width .2s, height .2s, box-shadow .2s, transform .2s .3s;
	transition: width .2s, height .2s, box-shadow .2s, transform .2s .3s, -webkit-box-shadow .2s, -webkit-transform .2s .3s
}

.player-controls-seek-bar-progress:hover:before {
	width: 24px;
	height: 24px;
	-webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .2)
}

.player-controls h3 {
	font-weight: 500;
	line-height: 1.1em;
}

.player-controls h4 {
	width: 100%;
	margin-left: auto;
	opacity: .7;
}

.player-controls .player-artwork {
	position: relative;
	align-self: flex-start;
	width: 70px;
	text-align: center;
	-webkit-transition: width .5s .3s;
	transition: width .5s .3s, border-radius .2s .3s, box-shadow .6s .2s, transform .2s;
	aspect-ratio: 1 / 1;
}

.player-controls .player-artwork-container {
	width: calc(300%);
	white-space: nowrap;
	transform: translate3d(-33.33333%, 0, 0);
}

.player-controls .player-artwork-container img {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% / 3);
	border-radius: 15px;
	-webkit-box-shadow: 0 4px 25px -5px currentColor;
	box-shadow: 0 4px 25px -5px currentColor;
	transition: border-radius .2s .3s, box-shadow .6s .2s, opacity .2s, color .6s .2s, ;
}

.player-controls .player-artwork .img-main {
	z-index: 1;
}

.player-controls .player-artwork .img-prev,
.player-controls .player-artwork .img-next {
	transform: scale(0.85) translate3d(0, 0, 0);
	opacity: 0;
}

body:not(.player-mini) .player-controls-handle:hover:before {
	transform: rotate(22deg);
	transform-origin: 14px 12px;
}

body:not(.player-mini) .player-controls-handle:hover:after {
	transform: rotate(-22deg);
	transform-origin: 14px 4px;
}

body.player-mini .player-header-description {
	padding-bottom: 140px;
	-webkit-transition-delay: .2s;
	transition-delay: .2s
}

body.player-mini .player-playlist-item:last-child {
	margin-bottom: 120px
}

body.player-mini .player-controls {
	bottom: 0;
	-webkit-transition-delay: .2s;
	transition-delay: .2s
}

body.player-mini .player-controls-wrapper {
	-webkit-box-shadow: 0 -8px 25px 0 rgba(0, 0, 0, .2);
	box-shadow: 0 -8px 25px 0 rgba(0, 0, 0, .2)
}

body.player-extended .player-controls {
	height: min(700px, 90%);
	transform: translate3d(0, 0, 0);
	-webkit-transition-delay: .1s;
	transition-delay: .1s
}

body.player-extended .player-controls-handle:before,
body.player-extended .player-controls-handle:after {
	top: min(11px, 75%);
	width: 24px;
}

body.player-extended .player-controls-handle:before {
	transform: rotate(-22deg);
}

body.player-extended .player-controls-handle:after {
	transform: rotate(22deg);
}

body.player-extended .player-controls-details {
	opacity: 0;
	transition-delay: 0s;
	pointer-events: none;
}

body.player-extended .player-controls-seek {
	width: 100%;
	padding: 0 20px
}

body.player-extended .player-controls-seek-bar-progress:before {
	-webkit-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1)
}

body.player-extended .player-controls .player-controls-wrapper {
	padding-top: min(40px, 6%);
	padding-bottom: min(40px, 6%);
	-webkit-box-shadow: 0 -8px 45px 40px rgba(0, 0, 0, .3);
	box-shadow: 0 -8px 45px 40px rgba(0, 0, 0, .3)
}

body.player-extended .player-controls .player-artwork {
	width: 100%;
	-webkit-transition-delay: .1s;
	transition-delay: .1s
}

body.player-extended .player-controls .player-artwork img {
	border-radius: 30px;
	-webkit-box-shadow: 0 30px 30px -20px currentColor;
	box-shadow: 0 30px 30px -20px currentColor;
}

body.swipe-right.player-extended .player-controls .player-artwork-container {
	animation: swipe-right .3s;
}

body.swipe-left.player-extended .player-controls .player-artwork-container {
	animation: swipe-left .3s;
}

body.swipe-right.player-extended .player-controls .player-artwork-container .img-prev,
body.swipe-left.player-extended .player-controls .player-artwork-container .img-next {
	animation: fade-out .9s;
}

body.swipe-right .player-controls .player-artwork-container .img-main,
body.swipe-left .player-controls .player-artwork-container .img-main {
	animation: fade-in .6s;
}

@media all and (max-width:401px) {
	.player-header {
		padding: 20px
	}

	.player-header-title {
		font-size: 1.6rem;
		line-height: 1.1em
	}

	.player-header-avatar:after {
		width: 50px
	}

	.player-header-avatar img {
		width: 44px
	}

	.player-header-description {
		padding-top: 70px
	}

	.player-content {
		height: calc(100% - 100px);
		padding-right: 10px;
		padding-left: 10px
	}

	.player-playlist {
		padding: 0;
	}

	.player-playlist-item h3 {
		margin-left: 10px;
		font-size: 1rem
	}

	.player-playlist-item h4 {
		font-size: .9rem
	}

	.player-playlist-item figure img {
		width: 60px
	}

	.player-controls-wrapper {
		padding: 20px
	}

	.player-controls h3 {
		font-size: 1em;
	}
}

@media all and (max-height: 760px) {
	.player-controls .player-artwork {
		margin-left: 0;
		transition: width .5s .3s, border-radius .2s .3s, box-shadow .6s .2s, transform .2s, margin .2s .3s;
	}

	body.player-extended .player-controls .player-artwork {
		width: 260px;
		margin-left: calc((100% - 260px) / 2);
	}
}


/* Player */

body.player-extended .player-controls-audioplayer {
	opacity: 1;
	transition: opacity .3s .6s;
}

.player-controls-audioplayer {
	width: 100%;
	padding: 0 20px;
	opacity: 0;
	transition: opacity .3s;
}

.rhap_progress-section {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.rhap_time {
	padding: 10px 0;
	opacity: .7;
}

.rhap_progress-container {
	display: flex;
	align-items: center;
	height: 20px;
	flex: 1 0 100%;
	align-self: center;
	margin: 0;
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
}

.rhap_progress-container:focus:not(:focus-visible) {
	outline: 0;
}

.rhap_progress-bar {
	box-sizing: border-box;
	position: relative;
	z-index: 0;
	width: 100%;
	height: 5px;
	border-radius: 10px;
	background: rgba(25, 24, 147, .3);
}

.rhap_progress-filled {
	position: relative;
	width: 0;
	height: 100%;
	border-radius: 10px;
	background: linear-gradient(to right, #040FD9, #030BA6);
	transition: width .2s cubic-bezier(.25, 1.1, .3, 1.1);
	cursor: pointer;
}

.rhap_progress-filled:before {
	content: '';
	position: absolute;
	inset: 50% 0 0 100%;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
	background: linear-gradient(45deg, #040FD9, #030BA6);
	transform: translate(-50%, -50%) scale(0);
	transition: width .2s, height .2s, box-shadow .2s, transform .2s .3s;
}

.rhap_progress-filled:hover:before {
	width: 24px;
	height: 24px;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .2);
}

.rhap_progress-indicator {
	position: absolute;
	top: -8px;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	border-radius: 50px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
	background: linear-gradient(45deg, #040FD9, #030BA6);
	z-index: 3;
}

.rhap_controls-section {
	margin-top: 20px;
}

.rhap_main-controls {
	display: flex;
	justify-content: center;
}

.rhap_main-controls-button {
	flex: 0 1 auto;
	width: 30%;
}

.rhap_main-controls-button svg {
	width: 50px;
	aspect-ratio: 1;
	fill: #030BA6;
}

.rhap_main-controls-button svg.disabled {
	opacity: .4;
}

/* Dark Theme */

@media all and (prefers-color-scheme: dark) {
	body {
		background: #292929;
	}

	#player {
		-webkit-box-shadow: 0 8px 35px 0 rgba(0, 0, 0, .5);
		box-shadow: 0 8px 35px 0 rgba(0, 0, 0, .5);
	}
}

@keyframes swipe-right {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-33.33333%);
	}
}

@keyframes swipe-left {
	0% {
		transform: translate3d(-66.66666%, 0, 0);
	}

	100% {
		transform: translate3d(-33.33333%, 0, 0);
	}
}

@keyframes fade-out {
	0% {
		transform: scale(1) translate3d(0, 0, 0);
		opacity: 100%;
	}

	50% {
		transform: scale(0.85) translate3d(0, 0, 0);
		opacity: 0;
	}

	100% {
		transform: scale(1) translate3d(0, 0, 0);
		opacity: 100%;
	}
}

@keyframes fade-in {
	0% {
		transform: scale(0.85) translate3d(0, 0, 0);
		opacity: 0;
	}

	100% {
		transform: scale(1) translate3d(0, 0, 0);
		opacity: 100%;
	}
}
